<template>
    <section>
        <the-navbar :public="true" />
        <div style="padding-top: 66px">
            <div class="section is-medium">
                <div class="card">
                    <header class="card-header">
                        <p class="card-header-title">
                            Recuperar el acceso a tu cuenta
                        </p>
                    </header>
                    <div class="card-content">
                        <form @submit.prevent="handleSubmit" v-if="!sended">
                            <b-field label="Email" label-position="on-border">
                                <b-input
                                    v-model="form.email"
                                    name="email"
                                    required
                                ></b-input>
                            </b-field>
                            <br />
                            <b-button
                                :loading="loading"
                                type="is-primary"
                                native-type="submit"
                                expanded
                            >
                                Enviar
                            </b-button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {};
</script>

<style>
</style>
